<template>
  <div>
    <div class="player_wrapper">
      <div class="profile">
        <!-- Use img if there is image for thumbnail, else use div. -->
        <!-- <img
          src="https://images.unsplash.com/photo-1484186139897-d5fc6b908812?ixlib=rb-0.3.5&s=9358d797b2e1370884aa51b0ab94f706&auto=format&fit=crop&w=200&q=80%20500w"
          class="thumbnail"
        /> -->
        <div :id="thumbnailId" class="thumbnailText">
          {{ player.gameName.charAt(0) }}
        </div>
        <div class="check">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <i class="fa" :class="statusIcon" v-bind="attrs" v-on="on"></i>
            </template>
            <span>{{ statusText }}</span>
          </v-tooltip>
        </div>
        <h3 class="game_name">{{ player.gameName }}</h3>
        <p class="pid">PID: {{ player.playerId }}</p>
        <p class="amount">Total Business: {{ player.amount }} MFC</p>
        <p class="date">With you since {{ installDate }}</p>
        <router-link
          :to="{
            path: pathToPlayerPage,
            query: {
              playerId: player.playerId,
              gameId: player.gameId,
              gameName: player.gameName,
              creditsBought: player.amount,
              installDate: installDate,
              status: player.status,
            },
          }"
          class="btn"
        >
          View
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/player_card.scss";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "@/utils/SageUtils";
import * as client from "@/client/webclient";

export default {
  props: {
    player: {},
    index: Number,
    page: Number,
  },
  data() {
    return {
      thumbnailId: "thumbnailId" + this.index.toString(),
    };
  },
  computed: {
    pathToPlayerPage: function () {
      return (
        "/referrals/" + this.player.gameId + "/" + this.player.playerId + ""
      );
    },
    statusIcon: function () {
      return this.player.status ? "fa-check" : "fa-exclamation";
    },
    statusText: function () {
      return this.player.status
        ? "Eligible for Referral Bonus"
        : "Recharge a Pack for the Player to get Referral Bonus";
    },
    installDate: function () {
      let timestamp = new Date(this.player.installTimestamp);
      return timestamp.toDateString();
    },
  },
  methods: {
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>