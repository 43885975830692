<template>
  <div>
    <div class="main-content">
      <div class="header" style="padding-bottom: 8rem !important">
        <ResellerHeader :walletCredits="credits" />
      </div>
      <!-- Page content -->
      <div class="container-fluid mt--7">
        <div class="row">
          <!-- Refer and Earn Card -->
          <div class="col-xl-4 order-2 mb-5 mb-xl-0">
            <div class="card card-profile shadow">
              <div
                class="row justify-content-center"
                style="padding-bottom: 6vh"
              >
                <div class="col-lg-3 order-lg-2">
                  <div class="card-profile-image">
                    <a href="#">
                      <img
                        src="../assets/prize.png"
                        class="rounded-circle"
                        style="object-fit: fill; height: 100px"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0 pt-md-4">
                <!-- Heading -->
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        card-profile-stats
                        d-flex
                        justify-content-center
                        mt-md-0
                      "
                    >
                      <div>
                        <span class="heading">Refer and Earn !!!</span>
                        <span class="description">
                          Refer Moonfrog Games to your friends using below link
                          and earn money.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Form -->
                <div class="text-center">
                  <!-- Referral Link -->
                  <div class="input-group col-lg-12 mb-2">
                    <input
                      v-model="referralLink"
                      id="referralLinkHolder"
                      type="text"
                      name="name"
                      placeholder="Referral-Link"
                      class="form-control bg-white border-right-0 border-md"
                      readonly
                    />
                    <div class="input-group-append">
                      <span
                        class="
                          input-group-text
                          bg-white
                          px-2
                          border-md border-left-0
                        "
                      >
                        <i
                          class="fa fa-clone text-muted"
                          style="cursor: pointer"
                          v-on:click="copyReferralToClipboard"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div class="share-networks">
                    <div class="row">
                      <div class="col-lg-12">
                        <ShareNetwork
                          network="sms"
                          :url="referralLink"
                          :title="socialTitle"
                          :description="socialMessage"
                          :quote="socialMessage"
                          hashtags=""
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                class="fa fa-comments-o"
                                v-bind="attrs"
                                v-on="on"
                              ></a>
                            </template>
                            <span>Share Link on SMS</span>
                          </v-tooltip>
                        </ShareNetwork>
                        <ShareNetwork
                          network="whatsapp"
                          :url="referralLink"
                          :title="socialTitle"
                          :description="socialMessage"
                          quote=""
                          hashtags=""
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                class="fa fa-whatsapp"
                                v-bind="attrs"
                                v-on="on"
                              ></a>
                            </template>
                            <span>Share Link on WhatsApp</span>
                          </v-tooltip>
                        </ShareNetwork>
                        <ShareNetwork
                          network="facebook"
                          :url="referralLink"
                          :title="socialTitle"
                          :description="socialMessage"
                          quote=""
                          hashtags=""
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                class="fa fa-facebook-square"
                                v-bind="attrs"
                                v-on="on"
                              ></a>
                            </template>
                            <span>Share Link on Facebook</span>
                          </v-tooltip>
                        </ShareNetwork>
                        <ShareNetwork
                          network="twitter"
                          :url="referralLink"
                          :title="socialTitle"
                          :description="socialMessage"
                          quote=""
                          hashtags=""
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                class="fa fa-twitter-square"
                                v-bind="attrs"
                                v-on="on"
                              ></a>
                            </template>
                            <span>Share Link on Twitter</span>
                          </v-tooltip>
                        </ShareNetwork>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <span class="fa fa-whatsapp"></span>
                    WhatsApp Direct
                  </div>
                  <div class="input-group col-lg-10 mx-auto mb-2">
                    <div class="input-group-prepend">
                      <vue-country-code
                        style="
                          border-top-right-radius: 0;
                          border-bottom-right-radius: 0;
                          border-color: #cad1d7;
                        "
                        enabledCountryCode="true"
                        ref="vcc"
                        :preferredCountries="['in', 'bd']"
                      >
                      </vue-country-code>
                    </div>
                    <input
                      v-model="inputPhNumber"
                      id="ph_number"
                      type="tel"
                      name="ph_number"
                      placeholder="WhatsApp Number"
                      aria-describedby="button-addon1"
                      class="form-control border-right-0 border-md bg-white"
                    />
                    <div class="input-group-append">
                      <span
                        class="
                          input-group-text
                          bg-white
                          px-2
                          border-md border-left-0
                        "
                      >
                        <i
                          class="fa fa-send text-muted"
                          style="cursor: pointer"
                          v-on:click="sendToWhatsapp"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-4">
                    <span class="fa fa-qrcode"></span>
                    QR Code
                  </div>
                  <div class="col-lg-12 mx-auto mt-1">
                    <qr-code :text="referralLink" class="qr-code"></qr-code>
                  </div>
                  <!-- Steps to Follow -->
                  <div
                    class="input-group col-lg-12 mb-4"
                    style="text-align: left; margin-top: 30px"
                  >
                    <span class="heading col-lg-12"> Steps to Follow: </span>
                    <span class="description" style="padding-left: 20px">
                      <ol>
                        <li>
                          Share the above Referral Link with your friends.
                        </li>
                        <li>
                          Ask them to open the link and click on any game on
                          your Referral Page.
                        </li>
                        <li>
                          They will be redirected to PlayStore / AppStore.
                        </li>
                        <li>
                          From there, they can install the game on their
                          devices.
                        </li>
                        <li>
                          Now, you will earn rewards for every recharge they
                          make through you.
                        </li>
                      </ol>
                    </span>
                  </div>
                  <hr class="my-4" />
                  <p>
                    By participating in Refer and Earn program, you agree to the
                    Moonfrog Labs
                    <a
                      href="https://moonfroglabs.com/terms-of-service/"
                      target="_blank"
                      >Terms of Service</a
                    >,
                    <a
                      href="https://moonfroglabs.com/privacy-policy-2/"
                      target="_blank"
                      >Privacy Policy</a
                    >
                    and
                    <router-link
                      :to="{ name: 'distributorTnC' }"
                      target="_blank"
                    >
                      Terms &amp; Conditions</router-link
                    >, that you may be bound by.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Left Side View -->
          <div class="col-xl-8 order-xl-1" style="padding-bottom: 2rem">
            <!-- Search Bar -->
            <div class="search-flex">
              <!-- Select Game -->
              <v-select
                v-model="selectedGameId"
                :items="gamesArr"
                item-text="name"
                item-value="gameId"
                label="Select Game"
                height="46px"
                clearable
                dense
                hide-details
                solo
                class="col-lg-4 bg-white mb-2"
                @change="fetchPlayer()"
              ></v-select>
              <v-spacer style="flex-grow: 0.1 !important"></v-spacer>
              <!-- Enter Player ID -->
              <div
                class="input-group col-lg-4 bg-white mb-2"
                style="padding: 0"
              >
                <input
                  v-model="playerId"
                  type="search"
                  placeholder="Enter Player ID"
                  aria-describedby="button-addon1"
                  class="form-control border-0 bg-white"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    @click="fetchPlayer()"
                    class="btn btn-link text-primary"
                    style="margin: 0"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <v-spacer style="flex-grow: 0.1 !important"></v-spacer>
              <!-- Download Button -->
              <div
                class="rounded bg-white mb-2"
                style="padding: 0; width: auto"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="button-addon1"
                      type="submit"
                      @click="downloadPlayersData()"
                      style="align-self: center; height: calc(2.75rem + 2px)"
                      class="form-control border-0 bg-white primary--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download last 50 Referral Install Data</span>
                </v-tooltip>
              </div>
            </div>

            <!-- Player Cards -->
            <div class="player-flex">
              <PlayerCard
                v-for="(player, index) in playersList"
                v-bind:player="player"
                v-bind:index="index"
                v-bind:key="player.playerId"
                v-bind:page="playersCurrentPage"
                class="player-card-component"
              ></PlayerCard>
            </div>
            <div class="text-center" style="padding-top: 2rem">
              <v-pagination
                v-model="playersCurrentPage"
                :length="playersTotalPage"
                :total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="getPlayers"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/reseller.css";
import ResellerHeader from "../components/ResellerHeader";
import PlayerCard from "../components/PlayerCard.vue";
import * as client from "@/client/webclient";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "../utils/SageUtils";
import apiConfig from "../config/api.config";

export default {
  name: "myReferrals",

  components: {
    ResellerHeader,
    PlayerCard,
  },

  props: {
    resellerId: String,
  },

  data() {
    return {
      credits: 0,

      selectedGameId: 0,
      gamesArr: [],

      playerId: "",
      referralLink: "",

      playersList: [],
      playersTotalPage: 1,
      playersCurrentPage: 1,

      socialTitle: "Play Moonfrog Games!",
      socialMessage:
        "1) Open the link\n2)Choose and download a game\n3)Install and login",

      inputPhNumber: "",
      waURL: "",
      isLoading: true,
    };
  },

  created() {
    this.referralLink =
      window.location.origin + "/referral/" + this.$store.state.account.email;
    this.init();
  },

  computed: {},

  methods: {
    async init() {
      try {
        this.credits = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.gamesArr = await client.getGames(this.$store.state.account.email);
        await this.getPlayers();
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    async getPlayers() {
      this.playerId = this.playerId.trim();
      this.isLoading = true;
      try {
        const getPlayersResponse = await client.getPlayers(
          this.playerId,
          this.$store.state.account.email,
          this.selectedGameId,
          this.$store.state.account.email,
          this.playersCurrentPage
        );
        this.playersList = getPlayersResponse.playersData;
        this.playersTotalPage = getPlayersResponse.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchPlayer() {
      this.playersCurrentPage = 1;
      await this.getPlayers();
    },

    async downloadPlayersData() {
      this.playerId = this.playerId.trim();
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$store.state.account.email,
          this.$store.state.account.email,
          "playerNetwork",
          {},
          this.selectedGameId,
          this.playerId
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    copyReferralToClipboard() {
      let Url = document.getElementById("referralLinkHolder");
      Url.select();
      document.execCommand("copy");
    },

    sendToWhatsapp() {
      let message = this.referralLink + " \n " + this.socialMessage;
      let whatsappNo =
        "+" + this.$refs.vcc.activeCountry["dialCode"] + this.inputPhNumber;
      SageUtils.sendMsgToWhatsapp(whatsappNo, message);
      this.$stats.StatsController.getInstance(
        this.$store.state.account.email,
        apiConfig.gameId,
        apiConfig.installOS,
        process.env.VUE_APP_STATS_URL
      ).count("mfsage", 1, "player whatsapp number", whatsappNo);
    },

    showWarningBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-md");
        document.getElementById(id).classList.add("border-danger");
      }
    },
    showNormalBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-danger");
        document.getElementById(id).classList.add("border-md");
      }
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>

<style>
.qr-code img {
  margin: auto !important;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 8px;
  padding: 4px;
}
</style>

<style scoped>
.search-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.player-flex {
  display: flex;
  flex-wrap: wrap;
  padding-left: inherit;
  padding-right: inherit;
  justify-content: center;
}

.player-card-component {
  padding: 1rem;
}

.share-networks .fa {
  padding: 7.5px;
  font-size: 32px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  vertical-align: center;
  border-radius: 50%;
  margin: 0 8px;
}

.share-networks .fa:hover {
  color: #3d3d3d !important;
}

/* Add a hover effect if you want */
.share-networks .fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.share-networks .fa-facebook-square {
  /* background: #3b5998; */
  color: #3b5998;
}

/* Twitter */
.share-networks .fa-twitter-square {
  /* background: #55acee; */
  color: #55acee;
}

.share-networks .fa-whatsapp {
  /* background: #25D366; */
  color: #25d366;
}

@media (max-width: 410px) {
  .player-flex {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
  .player-card-component {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>