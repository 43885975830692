var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"player_wrapper"},[_c('div',{staticClass:"profile"},[_c('div',{staticClass:"thumbnailText",attrs:{"id":_vm.thumbnailId}},[_vm._v(" "+_vm._s(_vm.player.gameName.charAt(0))+" ")]),_c('div',{staticClass:"check"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('i',_vm._g(_vm._b({staticClass:"fa",class:_vm.statusIcon},'i',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.statusText))])])],1),_c('h3',{staticClass:"game_name"},[_vm._v(_vm._s(_vm.player.gameName))]),_c('p',{staticClass:"pid"},[_vm._v("PID: "+_vm._s(_vm.player.playerId))]),_c('p',{staticClass:"amount"},[_vm._v("Total Business: "+_vm._s(_vm.player.amount)+" MFC")]),_c('p',{staticClass:"date"},[_vm._v("With you since "+_vm._s(_vm.installDate))]),_c('router-link',{staticClass:"btn",attrs:{"to":{
          path: _vm.pathToPlayerPage,
          query: {
            playerId: _vm.player.playerId,
            gameId: _vm.player.gameId,
            gameName: _vm.player.gameName,
            creditsBought: _vm.player.amount,
            installDate: _vm.installDate,
            status: _vm.player.status,
          },
        }}},[_vm._v(" View ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }